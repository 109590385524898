<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="listOfReviews"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(image)="data">
                                    <img
                                            v-if="data.item.image"
                                            :src="data.item.image"
                                            alt=""
                                            class="rounded mr-3"
                                            height="48"
                                    />
                                    <div
                                            v-if="!data.item.image"
                                            class="avatar-xs d-inline-block mr-2"
                                    >

                                    </div>

                                </template>
                                <template #cell(name)="data">
                                    <span class="font-15 font-weight-bold text-dark"> {{ data.item.name}}</span>
                                </template>
                                <template #cell(review)="data">
                                    <span class="font-14 text-dark"> {{ data.item.review}}</span>
                                </template>
                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template v-slot:cell(rating)="data">
                                      <span
                                              class="badge"
                                              :class="{
                                          'badge-success': data.item.rating >= 4,
                                          'badge-danger': data.item.rating < 3,
                                          'badge-warning':
                                            data.item.rating > 3 && data.item.rating < 4,
                                        }"
                                      ><i class="mdi mdi-star"></i> {{ data.item.rating }}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/reviews/'+data.item.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";

    export default {
        name: "ManageReviews",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                title: "List of reviews",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "reviews",
                        active: true,
                    },
                ],
                showLoader :false,
                listOfReviews : [],
                lisfOfReviewsRaw :[],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Drug name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label:"Drug image",
                        key: "image",
                        sortable: true,
                    },
                    {
                        label:"Ratings",
                        key: "rating",
                        sortable: true,
                    },
                    {
                        label:"Reviews",
                        key: "review",
                        sortable: true,
                    },
                    // {
                    //     label:"Quantity",
                    //     key: "quantity",
                    //     sortable: true,
                    // },
                    {
                        label:"Review date",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            getListOfProductReviews() {
                this.showLoader = true
                BaseUrl.AxiosBearer.get("/store/reviews/get-reviews").then((resp) => {
                this.showLoader = false
                    if(resp.data.status) {
                        console.log(" Reviews : ", resp.data?.payload)
                        this.listOfReviews = []
                        this.lisfOfReviewsRaw = []
                        this.lisfOfReviewsRaw = resp.data?.payload

                        resp.data?.payload.forEach((data) => {
                            this.listOfReviews.push({
                               id : data.id,
                                name : data.product?.productName,
                                image : data.product?.mainImage?.imageUrl,
                                rating : data?.ratings,
                                review : data?.review,
                                dateCreated : data?.createdAt
                            })
                        })
                    }

                }).catch((error) => {
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })

            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
          this.getListOfProductReviews()
        },
        computed: {
            rows() {
                return this.listOfReviews.length;
            },
        },
    }
</script>

<style scoped>

</style>